var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: "mdi-clipboard-list-outline",
        title: _vm.restrict
          ? "Professores das Atividades"
          : "Cadastro de Atividades"
      },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              !_vm.restrict
                ? _c("emc-excel-import-icon", {
                    attrs: {
                      param: "activity_items",
                      "tool-tip": "Atividades"
                    },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              !_vm.restrict
                ? _c("emc-excel-import-icon", {
                    attrs: {
                      param: "teacher_activities",
                      "tool-tip": "Professores das Atividades",
                      color: "primary"
                    },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              !_vm.isAnima()
                ? _c("emc-excel-export-icon", {
                    attrs: {
                      color: "purple",
                      "tool-tip": "Horário das Atividades",
                      "report-id": "47"
                    }
                  })
                : _vm._e(),
              _vm.isAnima()
                ? _c("s-icon-report-download", {
                    attrs: { "show-message": "", report: "21" },
                    on: { onCompleted: _vm.downloadCompleted }
                  })
                : _c("emc-excel-export-icon", { attrs: { "report-id": "21" } }),
              _c("s-icon-report-download", {
                attrs: {
                  "show-message": "",
                  "tool-tip": "Relatório Consolidado",
                  color: "primary",
                  report: "98"
                },
                on: { onCompleted: _vm.downloadCompleted }
              }),
              !_vm.restrict
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "indigo darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            _vm.showForm = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-plus")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3874217104
                      )
                    },
                    [_c("span", [_vm._v(" Cadastrar Nova Atividade ")])]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("sys-activity-item-filter", {
        attrs: { show: _vm.dialogFilter },
        on: {
          "update:show": function($event) {
            _vm.dialogFilter = $event
          },
          filter: function($event) {
            _vm.payloadFilter = $event
            _vm.getData()
            _vm.dialogFilter = false
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers.filter(function(h) {
            return !h.hidden
          }),
          params: _vm.params,
          collections: _vm.itemsFiltered,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: "",
          showDelete: !_vm.restrict,
          showAppend: ""
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.payloadFilter
                                          ? "primary"
                                          : "grey",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.dialogFilter = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { btn: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.payloadFilter
                                          ? "mdi-filter-menu-outline"
                                          : "mdi-filter-outline"
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Demais Filtros ")])]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.id) + " "),
                  item.attributions && item.attributions.length > 0
                    ? _c("small", [
                        _c("br"),
                        _vm._v(" Atribuição:"),
                        _c("br"),
                        _vm._v(_vm._s(item.attributions[0].id) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.campus",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.campus
                    ? _c("span", [_vm._v(" " + _vm._s(item.campus.name) + " ")])
                    : item.departament
                    ? _c("span", [_vm._v(_vm._s(item.departament.name))])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.teachers",
              fn: function(ref) {
                var item = ref.item
                return _vm._l(item.teachers, function(teacher, i) {
                  return _c("span", { key: i }, [
                    teacher.teacher
                      ? _c("span", [
                          i > 0 ? _c("br") : _vm._e(),
                          _vm._v(" " + _vm._s(teacher.teacher.name) + " "),
                          _c("small", [
                            _c("br"),
                            _vm._v(
                              "Valor: " + _vm._s(parseFloat(teacher.value))
                            )
                          ]),
                          _c("small", [
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm._f("date")(teacher.start)) +
                                " - " +
                                _vm._s(_vm._f("date")(teacher.end))
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                })
              }
            },
            {
              key: "item.type",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.valueType.name) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(item.periodicityType.name) + " ")
                ]
              }
            },
            {
              key: "item.activity",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.activity.name) + " "),
                  item.activity.category
                    ? _c("small", [
                        _c("br"),
                        _vm._v(_vm._s(item.activity.category.name))
                      ])
                    : _vm._e(),
                  item.activity.category && item.activity.category.departament
                    ? _c("small", [
                        _c("br"),
                        _vm._v(_vm._s(item.activity.category.departament.name))
                      ])
                    : _vm._e(),
                  item.timeTables.length
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small:
                                                _vm.$vuetify.breakpoint.name !=
                                                "xs",
                                              color: "purple"
                                            }
                                          },
                                          [_vm._v("mdi-calendar")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _c(
                              "ul",
                              _vm._l(item.timeTables, function(time, index) {
                                return _c("li", { key: index }, [
                                  item.by_date
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm._f("date")(time.date))
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            time.day_of_week
                                              ? time.day_of_week.name
                                              : ""
                                          )
                                        )
                                      ]),
                                  _vm._v(
                                    ": " +
                                      _vm._s(time.hour_start) +
                                      " - " +
                                      _vm._s(time.hour_end) +
                                      " "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  item.is_work_load
                    ? _c(
                        "span",
                        [
                          _c("br"),
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "success" } },
                            [_vm._v("mdi-check-bold")]
                          ),
                          _c("small", [_vm._v(" Compõe CH")])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.register",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.userCreator
                    ? _c("span", [
                        _vm._v(" " + _vm._s(item.userCreator.name) + " "),
                        _c("br"),
                        _c("small", [
                          _vm._v(_vm._s(_vm._f("dateTime")(item.created_at)))
                        ])
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm._f("dateTime")(item.created_at)))
                      ])
                ]
              }
            },
            _vm.showApproval
              ? {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          staticStyle: { width: "130px" },
                          attrs: {
                            color: _vm.tags[item.status.id - 1].color,
                            outlined: ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.tags[item.status.id - 1].icon) +
                                " "
                            )
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.tags[item.status.id - 1].name) +
                              " "
                          ),
                          item.note_approval
                            ? _c("emc-info-help", {
                                attrs: {
                                  icon: "mdi-chat-processing-outline",
                                  title: "Observação do aprovador"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.note_approval) +
                                              " "
                                          ),
                                          item.userApproval
                                            ? _c("span", [
                                                _c("br"),
                                                _c("br"),
                                                _vm._v("Aprovador: "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.userApproval.name
                                                    )
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          item.date_approval
                                            ? _c("small", [
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dateTime")(
                                                      item.date_approval
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              : null,
            _vm.showApproval
              ? {
                  key: "footer.prepend",
                  fn: function() {
                    return [
                      _c(
                        "v-chip-group",
                        {
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.filtersTags,
                            callback: function($$v) {
                              _vm.filtersTags = $$v
                            },
                            expression: "filtersTags"
                          }
                        },
                        _vm._l(
                          _vm.tags.filter(function(i) {
                            return i.active
                          }),
                          function(tag, i) {
                            return _c(
                              "v-chip",
                              {
                                key: i,
                                staticClass: "ma-2",
                                attrs: {
                                  color: _vm.filtersTags.includes(i)
                                    ? tag.color
                                    : "grey",
                                  "close-icon": _vm.filtersTags.includes(i)
                                    ? _vm.icons[0]
                                    : _vm.icons[1],
                                  close: "",
                                  outlined: "",
                                  height: "25",
                                  small: ""
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "" } },
                                  [_vm._v(" " + _vm._s(tag.icon) + " ")]
                                ),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(tag.name) +
                                      " (" +
                                      _vm._s(_vm.summary[i]) +
                                      ")"
                                  )
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          "prepend-inner-icon": "search",
                          "x-small": "",
                          placeholder: "Filtrar por solicitante",
                          rounded: "",
                          "single-line": "",
                          clearable: "",
                          autofocus: ""
                        },
                        model: {
                          value: _vm.searchUserCreator,
                          callback: function($$v) {
                            _vm.searchUserCreator = $$v
                          },
                          expression: "searchUserCreator"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c("sys-register-activity-item", {
        attrs: { restrict: _vm.restrict, model: _vm.model, show: _vm.showForm },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }